@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&display=swap");
body {
  font-family: "Noto Sans Devanagari", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background: #F6F1EB; */
}

.primary-color {
  color: #476fff;
}
.secondry-color {
  color: #59595a;
}

.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
}

.scroll-to-top-button {
  background-color: #030260;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px 15px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s, visibility 0.3s;
}

.scroll-to-top-button:hover {
  background-color: #0056b3;
}

.custom-bullet-color li::marker {
  color: #1e3a8a; /* Change to your desired color */
}
.bgImg {
  aspect-ratio: 5 / 1; 
}
.bgImg2{
  background: linear-gradient(186deg, rgba(40, 55, 88, 0.00) -107.72%, #F6F1EB 99.08%), url('./assets/Home/imgBg2.svg') lightgray 50% / cover no-repeat;
  background-size: cover;
  height: 40vh;
  background-position-x: 150px;
}

.notes-box-color{
  background: rgba(241, 94, 3, 0.07);
}

  .contact-form{
    background-color: #FFF9E0;
  }
  .contact-address{
    background-color:#2D2500;
  }
  .contact-form input{
    background-color: #FFF9E0;
  }

  li::marker {
    font-weight: 600;
    color: #030260;
}

/* ajay anurag home page section design */

@keyframes vibrate {
  0% {
    transform: translate(0);
  }
  10%, 90% {
    transform: translate(-2px, 2px);
  }
  20%, 80% {
    transform: translate(2px, -2px);
  }
  30%, 70% {
    transform: translate(-2px, -2px);
  }
  40%, 60% {
    transform: translate(2px, 2px);
  }
  50% {
    transform: translate(0);
  }
}

.vibrate {
  animation: vibrate 10s infinite;
}
.vibrate:hover{
  scale: 1.04;
}

.curved-img {
  clip-path: ellipse(120% 100% at 50% 0%);
}

/* ajay anurag home page section design end */

  